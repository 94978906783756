<template>
  <v-app>
    <AppLoader :loading="loading"></AppLoader>
    <router-view></router-view>
  </v-app>
</template>

<script>
import AppLoader from "@/components/shared/AppLoader";

export default {
  name: "App",
  components: {
    AppLoader,
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.loading = true;

    this.$store.dispatch("initState").then(() => {
      this.loading = false;
      this.calculatePadding();
    });
  },
  mounted() {
    window.addEventListener("resize", this.calculatePadding);
  },
  methods: {
    calculatePadding() {
      let appbarHeight = document.querySelector("#appheader").clientHeight;
      let pageContent = document.querySelector("#page-content");
      pageContent.style.paddingTop = appbarHeight + "px";
    },
  },
};
</script>
