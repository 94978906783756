/*
 | =========================
 | This File is for the router of application
 | =========================
 */
import Vue from "vue";
import VueRouter from "vue-router"

Vue.use(VueRouter)
export default new VueRouter({
  mode: "history",
  scrollBehavior() {
    return window.scrollTo({top: 0, behavior: 'smooth'});
  },
  routes: [
    {
      path: '',
      component: () => import("@/layouts/BaseLayout"),
      children: [
        {
          name: 'home',
          path: '/',
          component: () => import("@/pages/Home")
        },
        {
          name: 'home-services',
          path: '/home-services',
          component: () => import("@/pages/HomeServices")
        },
        {
          path: "/conventions",
          name: "conventions",
          component: () => import("@/pages/Conventions")
        },
        {
          path: "/products",
          name: "products",
          component: () => import("@/pages/Products")
        },
        {
          path: "/about",
          name: "about",
          component: () => import("@/pages/About")
        },
        {
          path: "/guarantees",
          name: "guarantees",
          component: () => import("@/pages/Guarantees")
        },
        {
          path: "/companies",
          name: "companies",
          component: () => import("@/pages/Companies")
        },
      ]
    },
    {
      path: "*",
      name: "page-not-found",
      component: () => import("@/pages/error")
    }
  ]
});
