import Vue from "vue";
import App from "./App";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import { VueMasonryPlugin } from "vue-masonry";

require("@/assets/style/style.scss");
Vue.config.productionTip = false;
Vue.use(VueMasonryPlugin);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
