/*
| ===================================================
| This File  is for the Loading component of the application
| ===================================================
*/
<template>
  <v-overlay :value="loading" opacity="1">
    <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "AppLoader",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.v-overlay--active {
  backdrop-filter: none !important;
  background: white !important;
}
</style>
