import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import COLORS from "@/assets/style/_exports.module.scss";
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
  iconfont: 'md',
  theme: {
    themes: {
      light: {
        primary: COLORS.PRIMARY,
        secondary: COLORS.SECONDARY,
        accent: COLORS.ASSENT
      },
    },
  },
});
