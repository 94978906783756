/*
| =========================
| This File is for the state management of app
| =========================
*/
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let baseUrl;
if (process.env.NODE_ENV === "development") {
  baseUrl = "http://localhost:8000/api/v1";
} else {
  baseUrl = "api/v1";
}

export default new Vuex.Store({
  state: {
    authToken: null,
    isLoggedIn: false,
    isStaff: false,
    profile: null,
    conventions: [],
    categories: [],
    companies: [],
    productsByCompany: [],
  },
  mutations: {
    setAuthState: (state, payload) => {
      state.isLoggedIn = payload;
    },
    setAuthToken: (state, payload) => {
      state.authToken = payload;
      localStorage.setItem("cbdigital_authToken", payload);
    },
    setConventions: (state, payload) => {
      state.conventions = payload;
    },
    setCategories: (state, payload) => {
      state.categories = payload;
    },
    setCompanies: (state, payload) => {
      state.companies = payload;
    },
    setProfile: (state, payload) => {
      state.profile = payload;
    },
    setCompaniesByProducts: (state, payload) => {
      state.productsByCompany = payload;
    },
  },
  getters: {
    getBaseUrl: () => {
      return baseUrl;
    },
    getAuthState: (state) => {
      return state.isLoggedIn;
    },
    getAuthToken: (state) => {
      return state.authToken;
    },
    getConventions: (state) => {
      return state.conventions.filter((c) => c.show);
    },
    getCategories: (state) => {
      return state.categories;
    },
    getCompanies: (state) => {
      return state.companies;
    },
    getProfile: (state) => {
      return state.profile;
    },
    getIsStaff: (state) => {
      return (
        state.profile &&
        (state.profile.groups || []).some((g) => {
          return g.toLowerCase().includes("staff");
        })
      );
    },
    getIsAgent: (state) => {
      return (
        state.profile &&
        (state.profile.groups || []).some((g) =>
          g.toLowerCase().includes("agenti")
        )
      );
    },
    getIsAWB: (state) => {
      return state.profile && state.profile.awb_user;
    },
    getHeaders: (state) => {
      const headers = {
        "Content-Type": "application/json",
      };
      if (state.authToken) {
        headers.Authorization = `Token ${state.authToken}`;
      }
      return headers;
    },
    getProductsByCompany: (state) => {
      return state.productsByCompany;
    },
  },
  actions: {
    async initState({ commit }) {
      const token = localStorage.getItem("cbdigital_authToken");
      if (token) {
        const response = await fetch(`${baseUrl}/auth/check`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });
        if (response.ok) {
          commit("setAuthState", true);
          commit("setAuthToken", token);
        } else {
          commit("setAuthState", false);
          commit("setAuthToken", null);
          localStorage.removeItem("cbdigital_authToken");
        }
      }

      if (this.getters.getAuthState && this.getters.getAuthToken) {
        await this.dispatch("getProfile");
        await this.dispatch("companies");
        await this.dispatch("productsByCompany");
      }

      await this.dispatch("conventions");
      await this.dispatch("categories");

      return;
    },
    async getProfile({ commit }) {
      const response = await fetch(`${baseUrl}/auth/profile`, {
        headers: this.getters.getHeaders,
      });
      const data = await response.json();
      if (response.ok) {
        commit("setProfile", data);
      } else {
        const error = new Error(response.statusText);
        error.data = data;
        throw error;
      }
    },
    async authenticate({ commit, dispatch }, { email, password }) {
      const response = await fetch(`${baseUrl}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, password: password }),
      });
      const data = await response.json();
      if (response.ok && data.token) {
        commit("setAuthState", true);
        commit("setAuthToken", data.token);
        this.dispatch("companies");
        this.dispatch("productsByCompany");
        this.dispatch("categories");
      } else {
        // Attach data to error object
        const error = new Error(response.statusText);
        error.data = data;
        throw error;
      }
    },
    async logout({ commit }) {
      const response = await fetch(`${baseUrl}/auth/logout`, {
        method: "POST",
        headers: this.getters.getHeaders,
      });
      // check if the response.json() is needed

      if (response.ok) {
        commit("setAuthState", false);
        commit("setAuthToken", null);
        localStorage.removeItem("cbdigital_authToken");
      } else {
        const error = new Error(response.statusText);
        throw error;
      }
    },
    async conventions({ commit }) {
      const response = await fetch(`${baseUrl}/conventions`, {
        headers: this.getters.getHeaders,
      });
      const data = await response.json();
      if (response.ok) {
        commit("setConventions", data);
      } else {
        const error = new Error(response.statusText);
        error.data = data;
        throw error;
      }
    },
    async companies({ commit }) {
      const response = await fetch(`${baseUrl}/companies`, {
        headers: this.getters.getHeaders,
      });
      const data = await response.json();
      if (response.ok) {
        commit("setCompanies", data);
      } else {
        const error = new Error(response.statusText);
        error.data = data;
        throw error;
      }
    },
    async categories({ commit }) {
      const response = await fetch(`${baseUrl}/categories`, {
        headers: this.getters.getHeaders,
      });
      const data = await response.json();

      if (response.ok) {
        commit("setCategories", data);
      } else {
        const error = new Error(response.statusText);
        error.data = data;
        throw error;
      }
    },
    async productsByCompany({ commit }) {
      const response = await fetch(`${baseUrl}/companies/products/?emission=true`, {
        headers: this.getters.getHeaders,
      });
      const data = await response.json();
      if (response.ok) {
        commit("setCompaniesByProducts", data);
      } else {
        const error = new Error(response.statusText);
        error.data = data;
        throw error;
      }
    },
    async createServiceRequest({ dispatch }, { name, email, phone }) {
      const response = await fetch(`${baseUrl}/requests/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, name: name, phone: phone }),
      });
      const data = await response.json();
      if (response.ok) {
      } else {
        // Attach data to error object
        const error = new Error(response.statusText);
        error.data = data;
        throw error;
      }
    },
    async getIpaUrl({ commit }, { url }) {
      const response = await fetch(`${baseUrl}/connect/ipa`, {
        headers: this.getters.getHeaders,
        data: { redirect_url: url },
      });
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        const error = new Error(response.statusText);
        error.data = data;
        throw error;
      }
    },
    async getFurnessUrl({ commit }, { url }) {
      const response = await fetch(`${baseUrl}/connect/furness`, {
        headers: this.getters.getHeaders,
        data: { redirect_url: url },
      });
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        const error = new Error(response.statusText);
        error.data = data;
        throw error;
      }
    },
    async retrieveExternalUrl({ commit }, { url, productId }) {
      const response = await fetch(
        `${baseUrl}/connect/${productId}/external?` +
          new URLSearchParams({
            redirect_url: url,
          }),
        {
          headers: this.getters.getHeaders,
        }
      );
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        const error = new Error(response.statusText);
        error.data = data;
        throw error;
      }
    },
  },
});
